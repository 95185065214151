import * as React from 'react';
import PageNotFound from '../features/pageNotFound/PageNotFound';
import { BrowserRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import NavBar from '../navigation/NavBar';
import MastercardPage, { ALLOWED_STATUSES } from '../features/mastercardPage';
import StatusPage from '../features/mastercardPage/StatusPage';
import { SessionProvider } from '../features/mastercardPage/session.context';
import Homepage from '../features/home';
import RefundPage from '../features/mastercardPage/RefundPage';
import TimeoutPage from '../features/timeoutPage/TimeoutPage';

const App = ({ logout }: { logout?: any }) => {
    return (
        <BrowserRouter>
            <SessionProvider>
                <div>
                    <NavBar logout={logout || null} />
                    <main className="page bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 bg-white">
                                    <div className="container">
                                        <br />
                                        <Switch>
                                            <Route
                                                exact
                                                path="/fake"
                                                render={(props) => <MastercardPage useFake={true} {...props} />}
                                            />
                                            <Route
                                                exact
                                                path="/fakewithretries"
                                                render={(props) => (
                                                    <MastercardPage useFake={true} withRetries={true} {...props} />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path="/fakerefundwithretries"
                                                render={(props) => (
                                                    <MastercardPage
                                                        useFake={true}
                                                        withRefundRetries={true}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path="/mockgoogleandapplepay"
                                                render={(props) => (
                                                    <MastercardPage mockGoogleAndApplePay={true} {...props} />
                                                )}
                                            />
                                            <Route exact path="/mastercard" component={MastercardPage} />
                                            <Route exact path="/mastercard/refund" component={RefundPage} />
                                            <Route
                                                path="/mastercard/:status"
                                                render={(props: RouteProps) => {
                                                    const {
                                                        match: {
                                                            params: { status },
                                                        },
                                                    } = props as any;
                                                    if (!ALLOWED_STATUSES.includes(status)) {
                                                        return <Redirect to="/error" />;
                                                    }

                                                    return <StatusPage {...props} />;
                                                }}
                                            />
                                            <Route exact path="/timeout" component={TimeoutPage} />
                                            <Route exact path="/" component={Homepage} />
                                            <Route component={PageNotFound} />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </SessionProvider>
        </BrowserRouter>
    );
};

export default App;
