import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import 'simple-react-notifications2/dist/index.css';
import AzureGuard from './app/AzureADO';

ReactDOM.render(
    <React.StrictMode>
        <AzureGuard />
    </React.StrictMode>,
    document.getElementById('root')
);
