import notifier from 'simple-react-notifications2';

notifier.configure({
    autoClose: 60000,
    pauseOnHover: false,
});

export function notifySuccessAlert(title: string, message: string) {
    notifier.success(`Success - ${title}`);
}

export function notifyErrorAlert(title: string, message: string) {
    notifier.error(`Error - ${title}`);
}

export function notifyInfoAlert(title: string, message: string) {
    notifier.info(`Info - ${title}`);
}
