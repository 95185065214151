import React, { useEffect } from 'react';
import App from './App';
import AzureAD, { AuthenticationState } from 'react-aad-msal';
import authProvider from './authProvider';
import { isAppDeployed } from '../config';

const Login = ({ login }: any) => {
    useEffect(() => {
        login();
    });
    return <h4>Redirecting to Login page...</h4>;
};

const AzureGuard = () => {
    const turnADOn = isAppDeployed === 'true';

    return turnADOn ? (
        <AzureAD provider={authProvider} forceLogin={true}>
            {({ accountInfo, authenticationState, login, logout }: any) => {
                if (accountInfo) {
                    localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
                }
                console.log({ accountInfo, authenticationState, login, a: AuthenticationState.Unauthenticated });
                return authenticationState === AuthenticationState.Unauthenticated ? (
                    <Login login={login} />
                ) : (
                    <App logout={logout} />
                );
            }}
        </AzureAD>
    ) : (
        <App />
    );
};

export default AzureGuard;
