let apiGatewayId: string;
export let targetEnvironment: string = process.env.REACT_APP_TARGET_ENVIRONMENT || 'dev';
targetEnvironment = targetEnvironment.trim();

export const isAppDeployed: string = process.env.REACT_APP_IS_APP_DEPLOYED || 'true';

switch (targetEnvironment) {
    case 'dev':
        apiGatewayId = '2tpojk07k9';
        break;
    case 'sbx':
        apiGatewayId = 'djx3n2zk0d';
        break;
    case 'qa':
        apiGatewayId = 'd4pb6px3r1';
        break;
    default:
        apiGatewayId = process.env.REACT_APP_API_GATEWAY_ID as string;
        break;
}

const baseUrl = `https://${apiGatewayId}.execute-api.eu-west-1.amazonaws.com/Prod/testclientapi`;
console.log(`baseURL=${baseUrl}`);

const config = {
    startNewPaymentSessionUrl: `${baseUrl}/startjourney`,
    authUrl: `${baseUrl}/auth`,
    captureUrl: `${baseUrl}/capture`,
    voidUrl: `${baseUrl}/void`,
    refundUrl: `${baseUrl}/refund`,
    getStatusUrl: `${baseUrl}/getstatus`,
    captureAsyncUrl: `${baseUrl}/asynccapture`,
    voidAsyncUrl: `${baseUrl}/asyncvoid`,
    refundAsyncUrl: `${baseUrl}/asyncrefund`,
};

export default config;
