import { DropInSession } from '@dpaas-payments-public/bppayui-sdk/lib/Mastercard/types';
import React, { useContext, useReducer } from 'react';

const initialState: DropInSession & { journeyId: string; googlePayPaymentData: Record<any, any> } = {
    dropInType: '',
    merchant: '',
    session: {
        id: '',
        updateStatus: '',
        version: '',
    },
    sessionType: '',
    journeyId: '',
    googlePayPaymentData: {},
};

export const actionTypes = {
    UPDATE_SESSION_ID: 'UPDATE_SESSION_ID',
    CLEAR_SESSION_ID: 'CLEAR_SESSION_ID',
};

const updateSessionId = (
    payload: { journeyId: string; dropInSession: DropInSession; googlePayPaymentData: Record<any, any> },
    state
) => {
    if (payload.googlePayPaymentData) {
        return {
            ...state,
            googlePayPaymentData: payload.googlePayPaymentData,
        };
    }

    let newState: any = {};

    if (payload.dropInSession) {
        newState = {
            ...newState,
            ...payload.dropInSession,
        };
    }

    if (payload.journeyId) {
        newState.journeyId = payload.journeyId;
    }

    return newState;
};

const clearSessionId = (_, __) => {
    localStorage.removeItem('pay-session');

    return initialState;
};

const actions = {
    [actionTypes.UPDATE_SESSION_ID]: updateSessionId,
    [actionTypes.CLEAR_SESSION_ID]: clearSessionId,
};

const reducer = (state: any, action: any) => {
    const { type, payload } = action;
    const newState = actions[type] ? actions[type](payload, state) : state;

    if (newState?.journeyId?.length > 0) {
        localStorage.setItem('pay-session', JSON.stringify(newState));
    }

    return newState;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SessionContext = React.createContext([initialState as any, () => {}]);

export const SessionProvider = ({ children }: any) => {
    const existingState = localStorage.getItem('pay-session') || '';
    const state = existingState.length > 0 ? JSON.parse(existingState) : initialState;
    const value = useReducer(reducer, state);

    return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export const useSessionId = () => {
    const contextValue = useContext(SessionContext);

    return contextValue;
};
