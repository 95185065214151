import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Switch from 'react-bootstrap/esm/Switch';

type BootstrapLinkProps = {
    to: string;
    label: string;
};

const BootstrapLink = (props: BootstrapLinkProps) => {
    return (
        <Link
            to={props.to}
            component={(props) => (
                <Nav.Link style={{ display: 'inline' }} href={props.href}>
                    {props.children}
                </Nav.Link>
            )}
        >
            {props.label}
        </Link>
    );
};

const NavBar = ({ logout }: any) => (
    <Navbar className="container" bg="primary" variant="dark" expand="lg">
        <Navbar.Brand href="/">
            <h4>Apollo 11 VAB</h4>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto inline">
                <Switch>
                    <BootstrapLink label="Mastercard" to="/mastercard" />
                    {logout && (
                        <div
                            onClick={(ev) => {
                                ev.preventDefault();
                                logout();
                            }}
                        >
                            <BootstrapLink label="Logout" to="/" />
                        </div>
                    )}
                </Switch>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

export default NavBar;
