import { uuid } from 'uuidv4';
import { notifySuccessAlert, notifyInfoAlert, notifyErrorAlert } from '../features/common/components/NotificationAlert';
import config from '../config';
import {
    CreateAuthData,
    CreateCaptureData,
    CreateVoidData,
    CreateRefundData,
    CreateAsyncCaptureData,
    CreateAsyncVoidData,
    CreateAsyncRefundData,
    CreateAuthDataGooglePay,
    CreateAuthDataApplePay,
} from '../requestData';
import { PaymentMethodTokenizationData } from '@dpaas-payments-public/bppayui-sdk/lib/google-pay';

async function post(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return response.json();
}

export const StartNewMastercardPaymentSession = async (data: any): Promise<any> => {
    notifyInfoAlert('Starting New Mastercard Payment Session', 'creating mastercard session id token');

    let returnResponse;
    await post(config.startNewPaymentSessionUrl, data)
        .then((response) => {
            notifySuccessAlert('Starting New Payment Session', 'Success - mastercard session id created');
            returnResponse = response;
        })
        .catch((error) => {
            notifyErrorAlert('Starting New Payment Session', error);
            console.error(error);
        });
    return returnResponse;
};

export const StartNewMastercardPaypalPaymentSession = async (data: any): Promise<any> => {
    notifyInfoAlert('Starting New Mastercard Paypal Payment Session', 'creating mastercard paypal session');
    console.log(data);

    let returnResponse;
    await post(config.startNewPaymentSessionUrl, {
        paymentMethodType: 'paypal',
        successUrl: `${window.location.origin}/mastercard/success`,
        tenantConfig: 'bppay',
    })
        .then((response) => {
            notifySuccessAlert('Starting New Payment Session', 'Success - mastercard paypal session created');
            returnResponse = response;
        })
        .catch((error) => {
            notifyErrorAlert('Starting New Payment Session', error);
            console.error(error);
        });
    return returnResponse;
};

export const StartNewMastercardGooglePayPaymentSession = async (mockGoogleAndApplePay = false): Promise<any> => {
    notifyInfoAlert('Starting New GooglePay Payment Session', 'creating googlepay session');

    let returnResponse;
    const data = mockGoogleAndApplePay
        ? { tenantConfig: 'Fake', paymentMethodType: 'googlepay' }
        : { paymentMethodType: 'googlepay' };

    await post(config.startNewPaymentSessionUrl, data)
        .then((response) => {
            notifySuccessAlert('Starting New Payment Session', 'Success - mastercard googlepay session created');
            returnResponse = response;
        })
        .catch((error) => {
            notifyErrorAlert('Starting New Payment Session', error);
            console.error(error);
        });
    return returnResponse;
};

export const StartNewMastercardApplePayPaymentSession = async (mockGoogleAndApplePay = false): Promise<any> => {
    notifyInfoAlert('Starting New ApplePay Payment Session', 'creating Applepay session');

    let returnResponse;
    const data = mockGoogleAndApplePay
        ? { tenantConfig: 'Fake', paymentMethodType: 'applepay' }
        : { paymentMethodType: 'applepay' };

    await post(config.startNewPaymentSessionUrl, data)
        .then((response) => {
            notifySuccessAlert('Starting New Payment Session', 'Success - mastercard applepay session created');
            returnResponse = response;
        })
        .catch((error) => {
            notifyErrorAlert('Starting New Payment Session', error);
            console.error(error);
        });
    return returnResponse;
};
export const StartNewPaymentSessionWithFakeGateway = async (
    withRetries: boolean,
    withRefundRetries: boolean
): Promise<any> => {
    notifyInfoAlert('Starting New Fake Payment Session', 'creating fake session id token');

    let returnResponse;
    const data = withRetries
        ? { tenantConfig: 'Fake', order: { orderId: `${uuid()}-RTY` } }
        : withRefundRetries
        ? { tenantConfig: 'Fake', order: { orderId: `${uuid()}-REF` } }
        : { tenantConfig: 'Fake' };

    await post(config.startNewPaymentSessionUrl, data)
        .then((response) => {
            notifySuccessAlert('Starting New Fake Session', 'Success - fake session id created');
            returnResponse = response;
        })
        .catch((error) => {
            notifyErrorAlert('Starting New Fake Payment Session', error);
            console.error(error);
        });
    return returnResponse;
};

export const AuthCard = async (journeyId: string, nonce: string, gateway = 'Mastercard') => {
    const data = CreateAuthData(journeyId, nonce, gateway);

    notifyInfoAlert('Authorising Card', 'card details sent for auth');

    return await authorise(data);
};

export const AuthCardGooglePay = async (journeyId: string, cardData: PaymentMethodTokenizationData) => {
    const data = CreateAuthDataGooglePay(journeyId, cardData);

    notifyInfoAlert('Authorising Google Pay Card', 'card details sent for auth');

    return authorise(data);
};

export const AuthCardApplePay = async (journeyId: string, cardData: PaymentMethodTokenizationData) => {
    const data = CreateAuthDataApplePay(journeyId, cardData);

    notifyInfoAlert('Authorising Apple Pay Card', 'card details sent for auth');

    return authorise(data);
};

export const CapturePayment = async (journeyId: string, amount: string): Promise<boolean | undefined> => {
    const data = CreateCaptureData(journeyId, amount);

    notifyInfoAlert('Capture Payment', 'Payment Submitted');

    let isSucceeded = false;
    await post(config.captureUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Captured') {
                notifySuccessAlert('Payment', 'Succeeded');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Payment', `${status} - ${message}`);
                console.error('Payment', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Payment', error);
            console.error(error);
            isSucceeded = false;
        });
    return isSucceeded;
};

export const RefundPayment = async (
    journeyId: string,
    amount: string,
    idempotencyKey: string
): Promise<boolean | undefined> => {
    const data = CreateRefundData(journeyId, amount, idempotencyKey);

    notifyInfoAlert('Refund Payment', 'Refund Submitted');

    let isSucceeded = false;
    await post(config.refundUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Refunded') {
                notifySuccessAlert('Refund', 'Succeeded');
                isSucceeded = true;
            } else if (status === 'PartiallyRefunded') {
                notifySuccessAlert('Partial Refund', 'Succeeded');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Refund', `${status} - ${message}`);
                console.error('Refund', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Payment', error);
            console.error(error);
            isSucceeded = false;
        });
    return isSucceeded;
};

export const GetStatus = async (journeyId: string): Promise<boolean | undefined> => {
    notifyInfoAlert('Get Status', 'Getting status of transaction');

    let isSucceeded = false;
    (await fetch(`${config.getStatusUrl}?journeyId=${journeyId}`))
        .json()
        .then((response) => {
            const status = response.status;
            if (status && (status !== 'DPaaSError' || status !== 'BadRequest')) {
                notifySuccessAlert(`${status} - Get Status`, 'Get Status');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Get Status', `${status} - ${message}`);
                console.error('Get Status', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Payment', error);
            console.error(error);
            isSucceeded = false;
        });
    return isSucceeded;
};

export const VoidPayment = async (journeyId: string) => {
    const data = CreateVoidData(journeyId);

    notifyInfoAlert('Void Payment', 'Void Submitted');

    let isSucceeded = false;

    await post(config.voidUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Voided') {
                notifySuccessAlert('Void Payment', 'Succeeded');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Void Payment', `${status} - ${message}`);
                console.error('Void Payment', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Void Payment', error);
            console.error(error);
        });

    return isSucceeded;
};

export const AsyncCapture = async (journeyId: string, amount: string, devEventId: string) => {
    const data = CreateAsyncCaptureData(journeyId, amount, devEventId);

    notifyInfoAlert('Capturing Payment Asynchronously', 'Capture Request Submitted');
    let isSucceeded = false;

    await post(config.captureAsyncUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Success') {
                notifySuccessAlert('Async Payment', 'Submitted');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Async Payment', `${status} - ${message}`);
                console.error('Async Payment', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Async Payment', error);
            console.error(error);
        });

    return isSucceeded;
};

export const AsyncVoid = async (journeyId: string, devEventId: string) => {
    const data = CreateAsyncVoidData(journeyId, devEventId);

    notifyInfoAlert('Voiding Payment Asynchronously', 'Void Request Submitted');
    let isSucceeded = false;

    await post(config.voidAsyncUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Success') {
                notifySuccessAlert('Async Void', 'Submitted');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Async Void', `${status} - ${message}`);
                console.error('Async Void', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Async Void', error);
            console.error(error);
        });

    return isSucceeded;
};

export const AsyncRefund = async (journeyId: string, amount: string, idempotencyKey: string, devEventId: string) => {
    const data = CreateAsyncRefundData(journeyId, amount, idempotencyKey, devEventId);

    notifyInfoAlert('Refunding Payment Asynchronously', 'Refund Request Submitted');
    let isSucceeded = false;

    await post(config.refundAsyncUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Success') {
                notifySuccessAlert('Async Refund', 'Submitted');
                isSucceeded = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Async Refund', `${status} - ${message}`);
                console.error('Async Refund', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Async Refund', error);
            console.error(error);
        });

    return isSucceeded;
};

async function authorise(data) {
    let isCardVerified = false;

    await post(config.authUrl, data)
        .then((response) => {
            const status = response.status;
            if (status === 'Authorised') {
                notifySuccessAlert('Auth Card', 'Succeeded');
                isCardVerified = true;
            } else {
                const message = response.message;
                notifyErrorAlert('Auth Card', `${status} - ${message}`);
                console.error('Auth Card', `${status} - ${message}`);
            }
        })
        .catch((error) => {
            notifyErrorAlert('Auth Card', error);
            console.error(error);
        });

    return isCardVerified;
}
