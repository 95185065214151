import { PaymentMethodTokenizationData } from '@dpaas-payments-public/bppayui-sdk/lib/google-pay';

export function CreateAuthData(journeyId: string, nonce: string, gateway: string) {
    if (gateway === 'PayPal') {
        return {
            journeyId: journeyId,
        };
    }

    const tokenType = 'SessionId';

    const data = {
        journeyId: journeyId,
        paymentMethod: {
            vaultType: gateway,
            tokenType,
            tokenValue: nonce,
        },
    };

    return data;
}

export function CreateAuthDataGooglePay(journeyId: string, cardData: PaymentMethodTokenizationData) {
    const data = {
        journeyId: journeyId,
        paymentMethod: cardData,
    };
    return data;
}

export function CreateAuthDataApplePay(journeyId: string, cardData: any) {
    const data = {
        journeyId: journeyId,
        paymentMethod: {
            data: cardData.token.paymentData.data,
            signature: cardData.token.paymentData.signature,
            version: 'EC_v1',
            header: cardData.token.paymentData.header,
        },
    };
    return data;
}

export function CreatePayment(journeyId: string, orderId: string, amount: string) {
    const data = {
        journeyId: journeyId,
        orderId: orderId,
        payment: {
            amount: amount,
            currency: 'GBP',
        },
    };

    return data;
}

export function CreateCaptureData(journeyId: string, captureAmount: string) {
    const data = {
        journeyId: journeyId,
        captureAmount: captureAmount,
    };

    return data;
}

export function CreateVoidData(journeyId: string) {
    const data = {
        journeyId: journeyId,
    };

    return data;
}

export function CreateRefundData(journeyId: string, refundAmount: string, idempotencyKey: string) {
    const data = {
        journeyId,
        refundAmount,
        idempotencyKey,
    };

    return data;
}

export function CreateIDPIdentityData() {
    const data = {
        IDPIdentity: CreateIDPIdentity(),
    };

    return data;
}

export function CreatePaymentData(selectedMethod: string) {
    const data = {
        IDPIdentity: CreateIDPIdentity(),
        PaymentMethodId: selectedMethod,
    };

    return data;
}

export function CreatePaymentForDeleteData(paymentMethodId: string) {
    const data = {
        IDPIdentity: CreateIDPIdentity(),
        PaymentMethodId: paymentMethodId,
    };

    return data;
}

export function CreateAsyncCaptureData(journeyId: string, captureAmount: string, devEventId: string) {
    const data = {
        journeyId: journeyId,
        captureAmount: captureAmount,
        eventCallback: { devEventId },
    };

    return data;
}

export function CreateAsyncVoidData(journeyId: string, devEventId: string) {
    const data = {
        journeyId: journeyId,
        eventCallback: { devEventId },
    };

    return data;
}

export function CreateAsyncRefundData(
    journeyId: string,
    refundAmount: string,
    idempotencyKey: string,
    devEventId: string
) {
    const data = {
        journeyId,
        refundAmount,
        idempotencyKey,
        eventCallback: { devEventId },
    };

    return data;
}

function CreateIDPIdentity() {
    const IDPIdentity = {
        Provider: 'Salesforce',
        Identity: 'hs8isyd',
    };

    return IDPIdentity;
}
