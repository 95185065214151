/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ALLOWED_STATUSES } from '.';
import {
    AuthCard,
    CapturePayment,
    VoidPayment,
    AsyncCapture,
    AsyncVoid,
    AuthCardGooglePay,
    GetStatus,
} from '../../services/service';

import { actionTypes, useSessionId } from './session.context';

const StatusPage = (props: Record<string, any>) => {
    const [sessionData, dispatch] = useSessionId();
    const [successStatus] = ALLOWED_STATUSES;
    const [shouldRenderCaptureBtn, renderCaptureBtn] = useState(undefined as never as boolean);
    const [isPaymentCaptured, changeCapturedState] = useState(undefined as never as boolean);
    const [amount, setAmount] = useState(0);

    const onClickHandler = async (journeyId: string, amount: number) => {
        const result = await CapturePayment(journeyId, `${amount}`);
        if (result) {
            renderCaptureBtn(false);
            changeCapturedState(true);
        }
    };

    const onClickHandlerForAsyncCapture = async (journeyId: string, amount: number, devEventId: string) => {
        //@ts-ignore
        const result = await AsyncCapture(journeyId, `${amount}`, devEventId);

        if (result) {
            renderCaptureBtn(false);
            changeCapturedState(false);
        }
    };

    const onClickHandlerForAsyncVoid = async (journeyId: string, devEventId: string) => {
        //@ts-ignore
        const result = await AsyncVoid(journeyId, devEventId);

        if (result) {
            renderCaptureBtn(false);
            changeCapturedState(false);
        }
    };

    const voidHandler = async (journeyId: string) => {
        const result = await VoidPayment(journeyId);
        if (result) {
            renderCaptureBtn(false);
            changeCapturedState(false);
        }
    };
    useEffect(() => {
        const cb = async () => {
            try {
                console.log({ sessionData });
                const paymentTypeMethod = sessionData.dropInType;
                const sessionId = sessionData.session?.id ?? '';
                const { journeyId } = sessionData;
                if (sessionData.googlePayPaymentData) {
                    const authResponse = await AuthCardGooglePay(
                        journeyId,
                        sessionData.googlePayPaymentData.paymentMethodData.tokenizationData
                    );
                    renderCaptureBtn(authResponse);
                    console.log('google-pay-response', authResponse);
                } else {
                    if (paymentTypeMethod === 'ApplePay') {
                        renderCaptureBtn(true);
                    } else {
                        const result = await AuthCard(journeyId, sessionId, paymentTypeMethod);
                        renderCaptureBtn(result);
                    }
                }
            } catch (e) {
                console.log('Card Auth error', e);
            }
        };
        if (props.match.params.status === successStatus) {
            cb();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <h1 data-qa="status-cancel-heading">Status: {props.match.params.status}</h1>
            <h3>PaymentMethodType: {sessionData.dropInType}</h3>
            <div>
                {sessionData?.session?.id?.length > 0 ? `Session: ${sessionData?.session?.id}` : 'No session set'}
            </div>
            <div>
                {sessionData?.devEventId?.length > 0 ? `devEventId: ${sessionData?.devEventId}` : 'No devEventId set'}
            </div>
            <div style={{ display: 'flex' }}>
                <div>{'journeyId:'}</div>
                <div id="journeyId">
                    {sessionData?.journeyId?.length > 0 ? sessionData?.journeyId : 'No journeyId set'}
                </div>
            </div>
            {shouldRenderCaptureBtn === true && (
                <div>
                    <div>
                        <label htmlFor={'capture-amount'}>Capture Amount: </label>
                        <input
                            id="capture-amount"
                            type="number"
                            value={amount}
                            onChange={(ev) => setAmount(parseInt(ev.target.value, 10))}
                        />
                    </div>
                    <br />
                    <br />
                    <div>
                        <button onClick={() => onClickHandler(sessionData.journeyId, amount)}> Capture Payment </button>
                    </div>
                    <hr />
                    <div>
                        <button onClick={() => voidHandler(sessionData.journeyId)}> Void Payment </button>
                        <hr />
                        <div>
                            <button
                                onClick={() =>
                                    onClickHandlerForAsyncCapture(sessionData.journeyId, amount, sessionData.devEventId)
                                }
                            >
                                Capture Payment Asynchronously
                            </button>
                        </div>
                        <hr />
                        <div>
                            <button
                                onClick={() =>
                                    onClickHandlerForAsyncVoid(sessionData.journeyId, sessionData.devEventId)
                                }
                            >
                                Void Payment Asynchronously
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isPaymentCaptured && (
                <div>
                    <hr />
                    <a href="/mastercard/refund">Refund a payment</a>
                </div>
            )}
            {shouldRenderCaptureBtn === false && isPaymentCaptured === false && <h2>Payment failed.</h2>}
            <hr />
            <button onClick={() => GetStatus(sessionData.journeyId)}>Get Transaction Status</button>
        </>
    );
};

export default StatusPage;
