import { ApplePayOpts, DropInSession } from '@dpaas-payments-public/bppayui-sdk/lib/apple-pay/types';
import ApplePayController from '@dpaas-payments-public/bppayui-sdk/lib/apple-pay';
import React, { useEffect, useState } from 'react';
import './apple-pay-btn.style.css';
import { Redirect } from 'react-router-dom';

function successCallback() {
    console.log('payment was successfull');
}

const ApplePayButtons = (props: ApplePayOpts) => {
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        new ApplePayController({
            appleButtonId: props.appleButtonId,
            errorMessageBoxId: props.errorMessageBoxId,
            paymentSession: props.paymentSession,
            authoriseCallback: props.authoriseCallback,
            successCallback: () => {
                successCallback();
                setRedirect(true);
            },
        });
    });

    return redirect ? (
        <Redirect push to="/mastercard/success" />
    ) : (
        <div>
            <h5>ApplePay</h5>
            <div id={props.appleButtonId} className="apple-pay-button apple-pay-button-text-buy" lang="en" />
            <p style={{ display: 'none' }} id={props.errorMessageBoxId} />
        </div>
    );
};

export default ApplePayButtons;
