/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { RefundPayment, AsyncRefund, GetStatus } from '../../services/service';
import { useSessionId } from './session.context';
import { v4 as uuidv4 } from 'uuid';

const RefundPage = () => {
    const [sessionData, dispatch] = useSessionId();
    const [journeyId, setJourneyId] = useState('');
    const [amount, setAmount] = useState('');
    const [idempotencyKey, setIdempotencyKey] = useState('');
    const [isRefundComplete, setRefundCompleteFlag] = useState(false);

    const onClickHandlerForAsyncRefund = async (devEventId: string) => {
        //@ts-ignore
        const result = await AsyncRefund(journeyId, `${amount}`, idempotencyKey, devEventId);

        if (result) {
            setRefundCompleteFlag(true);
        }
    };

    const onClickHandlerForRefund = async () => {
        const result = await RefundPayment(journeyId, `${amount}`, idempotencyKey);
        if (result) {
            setRefundCompleteFlag(true);
        }
    };

    useEffect(() => {
        setJourneyId(sessionData.journeyId);
    }, [sessionData.journeyId]);

    return isRefundComplete ? (
        <div>
            <h2>Refund Completed</h2>
            <hr />
            <button
                id=""
                onClick={() => {
                    setAmount('');
                    setIdempotencyKey('');
                    setRefundCompleteFlag(false);
                }}
            >
                New Refund
            </button>
            <hr />
            <button onClick={() => GetStatus(sessionData.journeyId)}>Get Transaction Status</button>
        </div>
    ) : (
        <>
            <div>
                <label htmlFor={'refund-journeyId'}>JourneyId: </label>
                <input
                    id="refund-journeyId"
                    type="text"
                    value={journeyId}
                    onChange={(ev) => {
                        setJourneyId(ev.currentTarget.value);
                    }}
                />
            </div>
            <div>
                <label htmlFor={'refund-amount'}>Amount: </label>
                <input
                    id="refund-amount"
                    type="text"
                    value={amount}
                    onChange={(ev) => setAmount(ev.currentTarget.value)}
                />
            </div>
            <div>
                <label htmlFor={'refund-idempotency'}>Idempotency Key: </label>
                <input
                    id="refund-idempotency-key"
                    type="text"
                    value={idempotencyKey}
                    onChange={(ev) => setIdempotencyKey(ev.currentTarget.value)}
                />
                <button onClick={() => setIdempotencyKey(uuidv4())}>Generate</button>
            </div>
            <br />
            <hr />
            <div>
                <button onClick={() => onClickHandlerForRefund()}>Refund</button>
            </div>
            <br />
            <div>
                <button onClick={() => onClickHandlerForAsyncRefund(sessionData.devEventId)}>Refund Async</button>
            </div>
            <hr />
            <button onClick={() => GetStatus(journeyId)}>Get Transaction Status</button>
        </>
    );
};

export default RefundPage;
