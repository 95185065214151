import React from 'react';
import { Link } from 'react-router-dom';
const HomePage = () => {
    return (
        <div>
            <h3>Payment Gateways</h3>
            <ul>
                <li>
                    <Link to="/mastercard">Mastercard</Link>
                </li>
                <li>
                    <Link to="/mastercard/refund">Mastercard Refund</Link>
                </li>
            </ul>
            <h4>Fake Gateway</h4>
            <ul>
                <li>
                    <Link to="/fake">Fake Gateway</Link>
                </li>
                <li>
                    <Link to="/fakewithretries">Fake Gateway with retries</Link>
                </li>
                <li>
                    <Link to="/fakerefundwithretries">Fake Gateway with retries for refund</Link>
                </li>
                <li>
                    <Link to="/mockgoogleandapplepay">Mock Google and Apple Pay and use Fake Gateway</Link>
                </li>
            </ul>
        </div>
    );
};

export default HomePage;
