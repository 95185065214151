import React, { useEffect, useState } from 'react';
import { MastercardDropIn } from '@dpaas-payments-public/bppayui-sdk';
import { useSessionId, actionTypes } from './session.context';
import { Redirect } from 'react-router-dom';
import {
    StartNewMastercardApplePayPaymentSession,
    StartNewMastercardGooglePayPaymentSession,
    StartNewMastercardPaymentSession,
    StartNewMastercardPaypalPaymentSession,
    StartNewPaymentSessionWithFakeGateway,
    AuthCardApplePay,
    GetStatus,
} from '../../services/service';
import PropTypes from 'prop-types';
import ApplePayButton from './apple-pay-btn';
import { GooglePayDropIn } from '@dpaas-payments-public/bppayui-sdk';
import { notifyErrorAlert } from '../common/components/NotificationAlert';
import {
    mockApplePayResponse,
    mockApplePayBadResponse,
    mockGooglePayResponse,
    mockGooglePayBadResponse,
} from '../../services/mock-responses';

const cancelCallback = () => console.log('custom cancel callback');
const errorCallback = (err) => console.log(`custom error callback ${JSON.stringify(err)}`);
const successCallback = () => console.log('custom success callback');

export const ALLOWED_STATUSES = ['success', 'cancel'];

let gateway;

const MastercardPage = (props) => {
    const [dropInSession, dispatch] = useSessionId();
    const [shouldRedirect, setRedirect] = useState(false);
    const [selectedMethod, setMethod] = useState('card');
    const [selectedTenant, setTenant] = useState('ApolloXI');

    useEffect(() => {
        dispatch({
            type: actionTypes.CLEAR_SESSION_ID,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isGooglePaySelectedMethod = selectedMethod === 'googlepay';
        gateway = new MastercardDropIn({
            config: {
                cancelCallback,
                errorCallback,
                successCallback,
                successCallbackUrl: '/mastercard/success',
                merchantName: window.localStorage.getItem('merchant-name'),
                targetWindow: window as Window,
            },
            dropInSession,
        });
        async function setupGooglePay() {
            const googlePay = new GooglePayDropIn(dropInSession, 'Sandbox');
            await googlePay.initialize();
            const button = googlePay.getGooglePayButton({ currency: 'GBP', amount: 100, countryCode: 'UK' });
            window.document.getElementById('googlePayButtonContainer')?.appendChild(button);
            googlePay.onGooglePayButtonClicked = async (transaction) => {
                try {
                    const responseGooglePay = await googlePay.pay(transaction);
                    dispatch({
                        type: actionTypes.UPDATE_SESSION_ID,
                        payload: { googlePayPaymentData: responseGooglePay },
                    });
                    window.location.href = '/mastercard/success';
                } catch (error) {
                    notifyErrorAlert('Authorising GooglePay Card', 'Failed to authorise');
                }
            };
        }
        if (isGooglePaySelectedMethod) {
            setupGooglePay();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropInSession]);

    useEffect(() => {
        const listener = () => {
            setRedirect(true);
        };
        const isPaypalSelectedMethod = selectedMethod === 'paypal';

        const retrieveSession = async () => {
            const getPaymentSession = getSession(selectedMethod);
            const dropInSessionResponse = props.useFake
                ? await StartNewPaymentSessionWithFakeGateway(props.withRetries, props.withRefundRetries)
                : props.mockGoogleAndApplePay
                ? await getPaymentSession(props.mockGoogleAndApplePay)
                : await getPaymentSession({tenantConfig: selectedTenant});

            dispatch({ type: actionTypes.UPDATE_SESSION_ID, payload: dropInSessionResponse || {} });
        };

        document.addEventListener(gateway.cancelEventName, () => {
            listener();
            document.removeEventListener(gateway.cancelEventName, listener);
        });

        if (dropInSession?.session?.id > 0 || isPaypalSelectedMethod) {
            dispatch({ type: actionTypes.CLEAR_SESSION_ID });
        }

        retrieveSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMethod, selectedTenant]);

    const onCheckout = (ev) => {
        ev.preventDefault();
        gateway.pay();
    };

    const onPaymentMethodChange = (ev) => {
        const value = ev.target.value;
        setMethod(value);
    };

    const onTenantChange = (ev) => {
        const value = ev.target.value;
        setTenant(value);
    };

    const mockGooglePayHandler = () => {
        try {
            dispatch({
                type: actionTypes.UPDATE_SESSION_ID,
                payload: { googlePayPaymentData: mockGooglePayResponse },
            });
            window.location.href = '/mastercard/success';
        } catch (error) {
            notifyErrorAlert('Authorising GooglePay Card', 'Failed to authorise');
        }
    };

    const mockGooglePayAuthFailHandler = () => {
        try {
            dispatch({
                type: actionTypes.UPDATE_SESSION_ID,
                payload: { googlePayPaymentData: mockGooglePayBadResponse },
            });
            window.location.href = '/mastercard/success';
        } catch (error) {
            notifyErrorAlert('Authorising GooglePay Card', 'Failed to authorise');
        }
    };

    const mockApplePayHandler = async () => {
        try {
            await authoriseApplePay(dropInSession.journeyId, mockApplePayResponse);
            setTimeout(() => {
                window.location.href = '/mastercard/success';
            }, 1000);
        } catch (error) {
            notifyErrorAlert('Authorising ApplePay Card', 'Failed to authorise');
        }
    };

    const mockApplePayAuthFailHandler = async () => {
        try {
            await authoriseApplePay(dropInSession.journeyId, mockApplePayBadResponse);
        } catch (error) {
            notifyErrorAlert('Authorising ApplePay Card', 'Failed to authorise');
        }
    };

    return shouldRedirect ? (
        <Redirect to="/mastercard/cancel" />
    ) : (
        <div>
            <form onSubmit={onCheckout}>
                <fieldset>
                    <legend>Mastercard Gateway Demo</legend>
                    <pre>
                        {`
new Mastercard({
  config: {
    cancelCallback: fn,
    errorCallback: fn,
    successCallback: fn,
    successCallbackUrl: string,
    version: number,
    targetWindow: Window,
    merchantName: string,
    locale: string
  },
  merchant: {
    merchantId: string
  }
})
        `}
                    </pre>
                    <p>
                        <label htmlFor="tenant-select">Tenant</label>

                        <select
                            name="tenant-select"
                            id="tenant-select"
                            onChange={onTenantChange}
                            value={selectedTenant}
                        >
                            <option value="ApolloXI">ApolloXI</option>
                            <option value="bppay">bppay</option>
                        </select>
                    </p>
                    <label htmlFor="method-select">Payment Method</label>

                    <select
                        name="method-select"
                        id="method-select"
                        onChange={onPaymentMethodChange}
                        value={selectedMethod}
                    >
                        <option value="card"> Card</option>
                        <option value="paypal">Paypal</option>
                        <option value="googlepay">Google Pay</option>
                        <option value="applepay">Apple Pay</option>
                    </select>
                    {selectedMethod === 'applepay' &&
                    dropInSession.dropInType === 'ApplePay' &&
                    props.mockGoogleAndApplePay ? (
                        <>
                            <br />
                            <button onClick={mockApplePayHandler}>Mock Apple Pay</button>
                            <br />
                            <br />
                            <button onClick={mockApplePayAuthFailHandler}>Mock Apple Pay Auth Fail</button>
                        </>
                    ) : (
                        selectedMethod === 'applepay' &&
                        dropInSession.dropInType === 'ApplePay' && (
                            <>
                                <ApplePayButton
                                    appleButtonId="applePayBtnId"
                                    errorMessageBoxId="errorMsgId"
                                    paymentSession={dropInSession}
                                    authoriseCallback={(token) => authoriseApplePay(dropInSession.journeyId, token)}
                                />
                                <div>sessionId: {dropInSession?.journeyId}</div>
                            </>
                        )
                    )}
                    {dropInSession.dropInType === 'PayPal' && <input type="submit" value="Pay with Paypal" />}
                    {selectedMethod === 'googlepay' && (
                        <>
                            <div id="googlePayButtonContainer"></div>
                            <br />
                            <button onClick={mockGooglePayHandler}>Mock Google Pay</button>
                            <br />
                            <br />
                            <button onClick={mockGooglePayAuthFailHandler}>Mock Google Pay Auth Fail</button>
                        </>
                    )}
                    {dropInSession?.session?.id?.length > 0 && (
                        <>
                            <div>sessionId: {dropInSession.session.id}</div>
                            <br />
                            <input type="submit" value="Start Checkout" />
                        </>
                    )}
                </fieldset>
            </form>
            <br />
            <button onClick={() => GetStatus(dropInSession.journeyId)}>Get Transaction Status</button>
        </div>
    );
};

MastercardPage.propTypes = {
    useFake: PropTypes.bool,
    withRetries: PropTypes.bool,
    withRefundRetries: PropTypes.bool,
    mockGoogleAndApplePay: PropTypes.bool,
};

export default MastercardPage;
function getSession(selectedMethod: string) {
    switch (selectedMethod) {
        case 'paypal':
            return StartNewMastercardPaypalPaymentSession;
        case 'googlepay':
            return StartNewMastercardGooglePayPaymentSession;
        case 'applepay':
            return StartNewMastercardApplePayPaymentSession;
        default:
            return StartNewMastercardPaymentSession;
    }
}

function authoriseApplePay(journeyId, data) {
    // Log out the Apple Pay test card token so we can use it in the bppay project.
    console.log('Apple Pay token data 1', data);
    console.log('Apple Pay token data 1 stringify', JSON.stringify(data, null, 2));
    return AuthCardApplePay(journeyId, data);
}
